function ChatGoToBottom(props) {
  const { atLastMessage, newMessages, goToBottom, lang } = props;

  return (
    <>
      {newMessages.length ? (
        <div
          style={{
            backgroundColor: "rgb(70 70 70 / 70%)",
            color: "white",
            borderRadius: "4px 4px 0 0",
            display: "flex",
            alignItems: "center",
            height: 50,
            cursor: "pointer",
          }}
          onClick={() => goToBottom()}
        >
          <div class="sp-text -ellipsis" style={{ margin: "0 22px 0 22px" }}>
            {newMessages[newMessages.length - 1].isBotMessage
              ? lang["__ai_chatbot"]
              : (newMessages[newMessages.length - 1].lastMessageName || "Admin")}{" "}
            :{" "}
            {newMessages[newMessages.length - 1].is_deleted ? lang['__unsent_message_chat_bottom']  
            : lang[newMessages[newMessages.length - 1].text] ||
              newMessages[newMessages.length - 1].text}
          </div>
        </div>
      ) : !atLastMessage ? (
        <div
          style={{
            backgroundColor: "rgb(70 70 70 / 70%)",
            color: "white",
            width: 50,
            height: 50,
            borderRadius: 25,
            display: "flex",
            margin: "0 auto 10px auto",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => goToBottom()}
        >
          <i class="icon-down-arrow"></i>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
